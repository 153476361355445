import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import MinimalLayout from 'layout/MinimalLayout';

// dashboard routing
const DashboardDefault = Loadable(lazy(() => import('views/dashboard/Default')));

// utilities routing
const UtilsTypography = Loadable(lazy(() => import('views/utilities/Typography')));
const UtilsColor = Loadable(lazy(() => import('views/utilities/Color')));
const UtilsShadow = Loadable(lazy(() => import('views/utilities/Shadow')));
const UtilsMaterialIcons = Loadable(lazy(() => import('views/utilities/MaterialIcons')));
const UtilsTablerIcons = Loadable(lazy(() => import('views/utilities/TablerIcons')));

// booking page routing
const BookingLessons = Loadable(lazy(() => import('views/booking-lessons')));

// purchase lessons page routing
const PurchaseLessons = Loadable(lazy(() => import('views/purchase-lessons')));

// register for sessional lessons page routing
const SessionalRegistration = Loadable(lazy(() => import('views/sessional-registration')));

// Checkout page routing
const Checkout = Loadable(lazy(() => import('views/checkout')));

// register for summer lessons page routing
const SummerRegistration = Loadable(lazy(() => import('views/summer-registration')));

// register for summer intro lessons page routing
const SummerIntroLessons = Loadable(lazy(() => import('views/summer-intro-lessons')));

// swimmer progress page routing
const SwimmerProgress = Loadable(lazy(() => import('views/swimmer-progress')));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
    path: '/',
    element: <MainLayout />,
    children: [
        {
            path: '/',
            element: <DashboardDefault />
        },
        {
            path: 'dashboard',
            children: [
                {
                    path: 'default',
                    element: <DashboardDefault />
                }
            ]
        },
        {
            path: 'lesson-registration',
            element: <SessionalRegistration />,
        },
        {
            path: 'checkout',
            element: <Checkout />
        },
        // {
        //     path: 'milton-lessons',
        //     element: <SummerRegistration />
        // },
        // {
        //     path: 'booking-lessons',
        //     element: <BookingLessons />
        // },
        // {
        //     path: 'purchase-lessons',
        //     element: <PurchaseLessons />
        // },
        {
            path: 'swimmer-progress',
            element: <SwimmerProgress />
        },
        {
            path: 'utils',
            children: [
                {
                    path: 'util-typography',
                    element: <UtilsTypography />
                }
            ]
        },
        {
            path: 'utils',
            children: [
                {
                    path: 'util-color',
                    element: <UtilsColor />
                }
            ]
        },
        {
            path: 'utils',
            children: [
                {
                    path: 'util-shadow',
                    element: <UtilsShadow />
                }
            ]
        },
        {
            path: 'icons',
            children: [
                {
                    path: 'tabler-icons',
                    element: <UtilsTablerIcons />
                }
            ]
        },
        {
            path: 'icons',
            children: [
                {
                    path: 'material-icons',
                    element: <UtilsMaterialIcons />
                }
            ]
        },
    ]
};

export default MainRoutes;
