import { arrayUnion, arrayRemove, serverTimestamp, setDoc, updateDoc, doc, getDoc, deleteDoc, collection, getDocs, onSnapshot, query, where, addDoc, orderBy } from 'firebase/firestore';
import { createContext, useContext, useEffect, useState } from 'react';
import { useMutation, useQuery } from 'urql';
import { useAuth } from './AuthContext';
import { db } from '../firebase.js';


const BookingContext = createContext();

export function useBooking() {
    return useContext(BookingContext);
}

export function BookingProvider({ children }) {
    const ADD_LESSON_TO_CART = `
    mutation($user: String!, $lesson: LessonInput!) {
        addLessonToCart(user: $user, lesson: $lesson ) {
            createdAt,

        }
    }
    `;

    const GET_CART = `
    query($user: String!) {
        getCart(user: $user) {
            createdAt,
            lessons {
                lessonId,
                location,
                instructor,
                startTime,
            }
        }
    }
    `;

    const REMOVE_LESSON_FROM_CART = `
    mutation($user: String!, $lesson: LessonInput!) {
        removeLessonFromCart(user: $user, lesson: $lesson) {
            createdAt,
            lessons {
                lessonId,
                location,
                instructor,
                startTime,
            }
        }
    }
    `;

    const CLEAR_CART = `
    mutation($user: String!) {
        clearCart(user: $user) {
            createdAt,
            lessons {
                lessonId,
                location,
                instructor,
                startTime,
            }
        }
    }
    `;

    const CONFIRM_CART = `
    mutation($user: String!) {
        confirmCartLessons(user: $user) {
            createdAt,
            lessons {
                lessonId,
                location,
                instructor,
                startTime,
            }
        }
    }
    `;
    const [removeFromCartResult, removeLessonFromCart] = useMutation(REMOVE_LESSON_FROM_CART);
    const [clearCartResult, clearLessonsFromCart] = useMutation(CLEAR_CART);
    const [addToCartResult, addLessonToCart] = useMutation(ADD_LESSON_TO_CART);
    const [confirmCartResult, confirmCartLessons] = useMutation(CONFIRM_CART);
    const { currentUser } = useAuth();
    const [userData, setUserData] = useState(undefined);


    const [cart, setCart] = useState([]);
    const [cartCreatedAt, setCartCreatedAt] = useState(null);
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);
    // Check if user has a cart already in the database, then set the cart state to the cart from the database
    // const [getCartResult, getCart] = useQuery({
    //     query: GET_CART,
    //     variables: {
    //         user: currentUser ? (currentUser.uid).toString() : null,
    //     },
    //     pause: !currentUser,
    // });

    // useEffect(() => {
    //     if (currentUser) {
    //         getCart();
    //     }
    // }, [cart, currentUser]);

    // const { data, fetching, error } = getCartResult;


    // if (error) return <p>Oh no... {error.message}</p>;

    // if (data && data.getCart && data.getCart.lessons && data.getCart.lessons.length > 0 && !cartCreatedAt) {
    //     setCartCreatedAt(data.getCart.createdAt);
    //     setCart(data.getCart.lessons);
    // }

    useEffect(() => {
        if (currentUser) {
            // Check how many lessons a user has in their bank
            const docRef = doc(db, "users", currentUser.uid);
            const unsub = onSnapshot(docRef, (doc) => {
                if (doc.exists()) {
                    const data = doc.data();
                    setUserData(data);
                }
            });
            return unsub;
        } else {
            setUserData(null);
        }
    }, [currentUser]);

    const convertDateToUTC = (date) => {
        const newDate = new Date(date);
        const now_utc = Date.UTC(newDate.getUTCFullYear(), newDate.getUTCMonth(), newDate.getUTCDate(), newDate.getUTCHours(), newDate.getUTCMinutes(), newDate.getUTCSeconds());
        return new Date(now_utc);
    }

    const addToCart = async (item) => {
        if (userData && userData.ClassesAvailable && userData.ClassesAvailable > 0) {
            // add via firebase
            const docRef = doc(db, "cart", currentUser.uid);
            const docSnap = await getDoc(docRef);
            if (docSnap.exists()) {
                await updateDoc(docRef, {
                    lessons: arrayUnion(item),
                    updatedAt: serverTimestamp(),
                });
                setCart([...cart, item]);
            }
            else {
                await setDoc((docRef), {
                    lessons: [item],
                    user: currentUser.uid,
                    createdAt: serverTimestamp(),
                });
                setCart([item]);
                setCartCreatedAt(new Date());
            }
            // update lesson in schedule collection
            const lessonRef = doc(db, "schedule", item.lessonId);
            const lessonSnap = await getDoc(lessonRef);
            if (lessonSnap.exists()) {
                await updateDoc(lessonRef, {
                    onHold: true,
                    user: currentUser.uid,
                    address: item.location,
                });
            } else {
                console.log("Lesson does not exist");
                setError("Lesson does not exist");
            }
            // update user's classes available without changing it in the database
            setUserData({ ...userData, ClassesAvailable: userData.ClassesAvailable - 1 });
        } else {
            setError("You do not have any classes available");
        }
    }

    const removeFromCart = async (item) => {
        const docRef = doc(db, "cart", currentUser.uid);
        await updateDoc(docRef, {
            lessons: arrayRemove(item),
            updatedAt: serverTimestamp(),
        }).then((result) => {
            console.log(result);
            setCart(cart.filter((cartItem) => cartItem.lessonId !== item.lessonId));
            if (cartItem.lessons.length === 0) {
                setCartCreatedAt(null);
            }
        }).catch((error) => {
            console.log(error);
        });
        // update user's classes available without changing it in the database
        setUserData({ ...userData, ClassesAvailable: userData.ClassesAvailable + 1 });
        // update lesson in schedule collection
        const lessonRef = doc(db, "schedule", item.lessonId);
        const lessonSnap = await getDoc(lessonRef);
        if (lessonSnap.exists()) {
            await updateDoc(lessonRef, {
                onHold: false,
                user: null,
                address: '',
            });
        } else {
            console.log("Lesson does not exist");
            setError("Lesson does not exist");
        }
    }

    const clearCart = async () => {
        // Delete cart from Firestore
        const docRef = doc(db, "cart", currentUser.uid);
        await deleteDoc(docRef);
        // Clear the cart
        setCart([]);
        setCartCreatedAt(null);
    }

    const confirmCart = async () => {
        // Put all lessons in cart off hold and set booked to true in the Firestore schedule collection
        const docRef = doc(db, "cart", currentUser.uid);
        const cartSnap = await getDoc(docRef);
        if (cartSnap.exists()) {
            cartSnap.data().lessons.forEach((lesson) => {
                const lessonRef = doc(db, "schedule", lesson.lessonId);
                updateDoc(lessonRef, {
                    onHold: false,
                    booked: true,
                    user: currentUser.uid,
                    address: lesson.location,
                });
            });
            // Remove number of lessons booked from lesson bank from their ClassesAvailable field
            const userRef = doc(db, "users", currentUser.uid);
            const userSnap = await getDoc(userRef);
            if (userSnap.exists()) {
                updateDoc(userRef, {
                    ClassesAvailable: userSnap.data().ClassesAvailable - cartSnap.data().lessons.length,
                });
            }
            setSuccess("Your lessons have been booked!");
        } else {
            console.log("Cart does not exist");
            setError("Cart does not exist");
        }
        // Clear the cart
        clearCart();
    }

    const cancelLesson = async (lesson) => {
        // Cancel lesson in schedule collection and add lesson back to lesson bank
        const lessonRef = doc(db, "schedule", lesson.id);
        const lessonSnap = await getDoc(lessonRef);
        if (lessonSnap.exists()) {
            await updateDoc(lessonRef, {
                onHold: false,
                booked: false,
                user: null,
                address: '',
            });
        } else {
            console.log("Lesson does not exist");
            setError("Lesson does not exist");
        }
        // Add number of lessons booked back to lesson bank from their ClassesAvailable field
        const userRef = doc(db, "users", currentUser.uid);
        const userSnap = await getDoc(userRef);
        if (userSnap.exists()) {
            updateDoc(userRef, {
                ClassesAvailable: userSnap.data().ClassesAvailable + 1,
            });
        }
    }

    //Functions to fetch Swimmer Data

    const getSwimmerDataByUserId = async () => {
        try {
            const userRef = doc(db, "users", currentUser.uid);
            const userSnapshot = await getDoc(userRef);
            const userData = userSnapshot.data();
            const swimmerRef = collection(userRef, "swimmers");
            const swimmerSnapshot = await getDocs(swimmerRef);
            const swimmerData = [];
            swimmerSnapshot.forEach((doc) => {
                const swimmerId = doc.id;
                const data = doc.data();
                swimmerData.push({
                    id: swimmerId,
                    name: `${data.firstName} ${data.lastName}`,
                });
            });
            return swimmerData;
        } catch (error) {
            console.error("Error retrieving swimmer data:", error);
            throw error;
        }
    };


    const getSwimmerDetailsById = async (id) => {
        try {
            const docRef = doc(db, "reports", id);
            const docSnap = await getDoc(docRef);

            if (docSnap.exists()) {
                const data = docSnap.data();
                console.log("Swimmer details:", data);
                return data;
            } else {
                console.log("Swimmer Report with ID", id, "does not exist");
                return null;
            }
        } catch (error) {
            console.error("Error retrieving swimmer details:", error);
            throw error;
        };
    }

    const getAvailableSessionDates = async (lessonType) => {
        try {
            const docRef = collection(db, 'sessionalSchedule');
            const docSnap = await getDocs(docRef);

            const lessonData = [];

            docSnap.forEach((doc) => {
                doc.data().id = doc.id;
                lessonData.push({
                    id: doc.id,
                    ...doc.data()
                });
            });

            return lessonData;

        } catch (error) {
            throw error;
        }
    }

    const getAccountSwimmers = async () => {
        try {
            const docRef = collection(db, 'swimmers');
            const q = query(docRef, where('user', '==', currentUser.uid));

            const swimmerData = [];

            const querySnapshot = await getDocs(q);
            querySnapshot.forEach((doc) => {
                doc.data().id = doc.id;
                swimmerData.push({
                    id: doc.id,
                    ...doc.data()
                });
            });

            return swimmerData;
        } catch (error) {
            throw error;
        }
    }

    const addSwimmerInfo = async (fname, lname, birthday) => {
        try {
            const docRef = collection(db, 'swimmers');
            const docSnap = await addDoc(docRef, {
                firstName: fname,
                lastName: lname,
                birthday: birthday,
                user: currentUser.uid,
            });
            return docSnap;
        } catch (error) {
            console.error("Error adding swimmer:", error);
            throw error;
        }
    }

    const getInstructors = async () => {
        try {
            const docRef = collection(db, 'instructors');
            const docSnap = await getDocs(docRef);

            const instructorData = [];

            docSnap.forEach((doc) => {
                doc.data().id = doc.id;
                instructorData.push({
                    id: doc.id,
                    ...doc.data()
                });
            });

            return instructorData;

        } catch (error) {
            throw error;
        }
    }

    const putLessonOnHold = async (lessonId) => {
        try {
            const docRef = doc(db, "sessionalSchedule", lessonId);
            const docSnap = await getDoc(docRef);

            if (docSnap.exists()) {
                // change lesson to on hold
                await updateDoc(docRef, {
                    onHold: true,
                    user: currentUser.uid,
                    updatedAt: serverTimestamp(),
                });
                return true;
            } else {
                console.log("Lesson with ID", lessonId, "does not exist");
                return null;
            }
        } catch (error) {
            console.error("Error retrieving lesson details:", error);
            throw error;
        };
    }

    // Remove hold on lesson
    const removeLessonHold = async (lessonId) => {
        try {
            const docRef = doc(db, "sessionalSchedule", lessonId);
            const docSnap = await getDoc(docRef);

            if (docSnap.exists()) {
                // change lesson to on hold
                await updateDoc(docRef, {
                    onHold: false,
                    user: null,
                    updatedAt: serverTimestamp(),
                });
                return true;
            } else {
                console.log("Lesson with ID", lessonId, "does not exist");
                return null;
            }
        } catch (error) {
            console.error("Error retrieving lesson details:", error);
            throw error;
        };
    }

    // Temporarily add swimmers to lesson if it's multiClient
    const addTempSwimmersToLesson = async (lessonId, swimmer) => {
        try {
            const docRef = doc(db, "sessionalSchedule", lessonId);
            const docSnap = await getDoc(docRef);

            if (docSnap.exists()) {
                // add swimmer to currentParticipants array
                await updateDoc(docRef, {
                    currentParticipants: arrayUnion(swimmer.id),
                    tempSwimmer: arrayUnion(swimmer.id),
                    updatedAt: serverTimestamp(),
                });
                return true;
            } else {
                console.log("Lesson with ID", lessonId, "does not exist");
                return null;
            }
        }
        catch (error) {
            console.error("Error retrieving lesson details:", error);
            throw error;
        };
    }

    // Remove temp user from lesson
    const removeTempSwimmer = async (lessonId, swimmer) => {
        try {
            const docRef = doc(db, "sessionalSchedule", lessonId);
            const docSnap = await getDoc(docRef);

            if (docSnap.exists()) {
                // remove swimmer from currentParticipants array
                await updateDoc(docRef, {
                    currentParticipants: arrayRemove(swimmer.id),
                    tempSwimmer: arrayRemove(swimmer.id),
                    updatedAt: serverTimestamp(),
                });
                return true;
            } else {
                console.log("Lesson with ID", lessonId, "does not exist");
                return null;
            }
        }
        catch (error) {
            console.error("Error retrieving lesson details:", error);
            throw error;
        };
    }

    // Add swimmers to group lesson
    const addSwimmerToGroupLesson = async (lessons) => {
        try {
            lessons.forEach(async (lesson) => {
                const docRef = doc(db, "sessionalSchedule", lesson.id);
                const docSnap = await getDoc(docRef);

                if (docSnap.exists()) {
                    // remove the swimmer from the tempSwimmer array
                    await updateDoc(docRef, {
                        tempSwimmer: arrayRemove(lesson.swimmer.id),
                        updatedAt: serverTimestamp(),
                    });
                } else {
                    console.log("Lesson with ID", lesson.id, "does not exist");
                    return null;
                }
            }
            );
            return true;
        }
        catch (error) {
            console.error("Error retrieving lesson details:", error);
            throw error;
        };
    }

    const addSwimmersToLesson = async (lessonId, swimmers) => {
        try {
            const docRef = doc(db, "sessionalSchedule", lessonId);
            const docSnap = await getDoc(docRef);

            if (docSnap.exists()) {
                // change lesson to on hold
                await updateDoc(docRef, {
                    // only add swimmer ids to the array
                    currentParticipants: arrayUnion(...swimmers.map((swimmer) => swimmer.id)),
                    updatedAt: serverTimestamp(),
                    available: false,
                    onHold: false,
                });
                return true;
            } else {
                console.log("Lesson with ID", lessonId, "does not exist");
                return null;
            }
        } catch (error) {
            console.error("Error retrieving lesson details:", error);
            throw error;
        };
    }

    const getSessions = async () => {
        try {
            // Order by startDate
            // Get sessions where the registration date has not passed
            const date = new Date();
            const docRef = collection(db, 'sessions');
            const docSnap = await getDocs(query(docRef, where('registrationEnd', '>=', date), orderBy('registrationEnd', 'asc')));

            const sessionData = [];

            docSnap.forEach((doc) => {
                doc.data().id = doc.id;
                sessionData.push({
                    id: doc.id,
                    ...doc.data()
                });
            });

            return sessionData;
        } catch (error) {
            throw error;
        }
    }

    const getLessonTypes = async () => {
        // Get all lesson types where active is true
        try {
            const docRef = collection(db, 'lessonTypes');
            const docSnap = await getDocs(query(docRef, where('active', '==', true)));

            const lessonData = [];

            docSnap.forEach((doc) => {
                doc.data().id = doc.id;
                lessonData.push({
                    id: doc.id,
                    ...doc.data()
                });
            });

            return lessonData;
        } catch (error) {
            throw error;
        }

    }

    const getSkillRanges = async () => {
        try {
            const docRef = collection(db, 'skillRanges');
            const docSnap = await getDocs(docRef);

            const skillData = [];

            docSnap.forEach((doc) => {
                doc.data().id = doc.id;
                skillData.push({
                    id: doc.id,
                    ...doc.data()
                });
            });

            return skillData;

        } catch (error) {
            throw error;
        }
    }

    const getUserLessonBank = async () => {
        try {
            const docRef = doc(db, "users", currentUser.uid);
            const docSnap = await getDoc(docRef);

            if (docSnap.exists()) {
                const data = docSnap.data();
                return data.ClassesAvailable;
            } else {
                console.log("User with ID", currentUser.uid, "does not exist");
                return null;
            }
        } catch (error) {
            console.error("Error retrieving user lesson bank:", error);
            throw error;
        };
    }

    const removeUserLessonBank = async (amount) => {
        try {
            const docRef = doc(db, "users", currentUser.uid);
            const docSnap = await getDoc(docRef);

            if (docSnap.exists()) {
                // remove the amount from the user's ClassesAvailable field
                await updateDoc(docRef, {
                    ClassesAvailable: docSnap.data().ClassesAvailable - amount,
                });
                return true;
            } else {
                console.log("User with ID", currentUser.uid, "does not exist");
                return null;
            }
        } catch (error) {
            console.error("Error retrieving user lesson bank:", error);
            throw error;
        };
    }


    const value = {
        addToCart,
        removeFromCart,
        clearCart,
        confirmCart,
        cart,
        cartCreatedAt,
        success,
        cancelLesson,
        getSwimmerDataByUserId,
        getSwimmerDetailsById,
        getAvailableSessionDates,
        userData,
        error,
        getAccountSwimmers,
        addSwimmerInfo,
        getInstructors,
        putLessonOnHold,
        removeLessonHold,
        addTempSwimmersToLesson,
        removeTempSwimmer,
        addSwimmerToGroupLesson,
        addSwimmersToLesson,
        getSessions,
        getLessonTypes,
        getSkillRanges,
        getUserLessonBank,
        removeUserLessonBank,
    };

    return (
        <BookingContext.Provider value={value}>
            {children}
        </BookingContext.Provider>
    )
}