import { auth, db } from "firebase.js";
import {
  createUserWithEmailAndPassword,
  onAuthStateChanged,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signOut,
} from "firebase/auth";
import { collection, doc, getDocs, query, setDoc, where } from "firebase/firestore";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useMutation } from "urql";

const AuthContext = React.createContext();

export function useAuth() {
  return useContext(AuthContext);
}

export function AuthProvider({ children }) {
  const [currentUser, setCurrentUser] = useState(null);
  const [idToken, setIdToken] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isNewUser, setIsNewUser] = useState(false);
  const navigate = useNavigate();

  // graphql mutation add user account
  const [addNewUserResult, submitAddNewUser] = useMutation(`
        mutation($email: String!, $fname: String!, $lname: String!, $street: String!, $city: String!, $postalCode: String!, $phoneNumber: String!, $user: String!) {
            addNewUser(Email: $email, FirstName: $fname, LastName: $lname, Street: $street, City: $city, PostalCode: $postalCode, PhoneNumber: $phoneNumber, user: $user) {
                Email
                FirstName
                LastName
                Street
                City
                PostalCode
                PhoneNumber
            }
        }
    `);

  const signUpWithEmailAndPassword = async (email, password) => {
    try {
      setLoading(true);
      createUserWithEmailAndPassword(auth, email, password).then((user) => {
        navigate("/onboarding", { replace: true });
      });
    } catch (error) {
      setLoading(false);
      setError(error);
    }
  };

  const addUserInfo = async (
    email,
    fname,
    lname,
    street,
    city,
    postalCode,
    phoneNumber,
    user
  ) => {
    // try {
    //     submitAddNewUser({
    //         Email: email,
    //         FirstName: fname,
    //         LastName: lname,
    //         Street: street,
    //         City: city,
    //         PostalCode: postalCode,
    //         PhoneNumber: phoneNumber,
    //         user: user
    //     }).then((result) => {
    //         const { data, error } = result;
    //         if (error) {
    //             setError(error);
    //             console.log(error);
    //         }
    //         if (data) {
    //             console.log(data);
    //             setError(null);
    //             navigate('/', { replace: true });
    //         }
    //     });
    // } catch (error) {
    //     setError(error);
    //     console.log(error);
    // }

    const docRef = doc(db, "users", user.uid);
    await setDoc(docRef, {
      Email: email,
      FirstName: fname,
      LastName: lname,
      Address: street,
      City: city,
      PostalCode: postalCode,
      PhoneNumber: phoneNumber,
      user: user.uid,
      ClassesAvailable: 0,
    })
      .then((setUser) => {
        navigate("/", { replace: true });
      })
      .catch((error) => {
        setError(error);
        console.log(error);
      });
  };

  const logInWithEmailAndPassword = async (email, password) => {
    try {
      // Check if the user exists in the users collection
      // const usersCollectionRef = collection(db, "users");
      // const querySnapshot = await getDocs(
      //   query(usersCollectionRef, where("Email", "==", email))
      // );
      // if (querySnapshot.empty) {
      //   // The user does not exist in the instructors collection
      //   console.log("User does not exist in the collection");
      //   // Handle the appropriate action, such as showing an error message
      //   return;
      // } else {
      await signInWithEmailAndPassword(auth, email, password).then(() => {
        setError(null);
        navigate("/", { replace: true }),
        console.log("User Login")
      });
    } catch (error) {
      setError(error);
    }
  };

  const logout = () => {
    signOut(auth).then(() => {
      navigate("/login", { replace: true });
    });
  };

  const resetPassword = (email) => {
    sendPasswordResetEmail(auth, email)
      .then(() => {
        // Email sent.
        setError(null);
      })
      .catch((error) => {
        setError(error);
      });
  };

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      // determine if user is new
      setCurrentUser(user);
      setLoading(false);
    });

    return unsubscribe;
  }, []);

  // Update users ID token every time it changes
  useEffect(() => {
    if (currentUser) {
      currentUser
        .getIdToken(true)
        .then((idToken) => {
          setIdToken(idToken);
        })
        .catch((error) => {
        });
    }
  }, [currentUser]);

  const value = {
    currentUser,
    logInWithEmailAndPassword,
    logout,
    idToken,
    resetPassword,
    error,
    setError,
    signUpWithEmailAndPassword,
    loading,
    addUserInfo,
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
}
